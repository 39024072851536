import BaseOrder from "./BaseOrder";
import OrderForm from "./OrderForm";
import { stateNameByValue } from "@/src/states";
import { cloneDeep } from "lodash";
import { fsEvent } from "~/services/util";
import { Liquid } from "liquidjs";
const liquidEngine = new Liquid();

export default class Document extends BaseOrder {
  static entity = "documents";

  static fields() {
    return {
      id: this.attr(null),
      state: this.attr(null),
      version_slug: this.attr(""),
      product_id: this.attr(""),
      type: this.attr(""),
      data: this.attr({}),
      current_page_id: this.attr(-1),
      completed: this.attr(false),
      company_id: this.attr(null),
      send_back_to_review: this.attr(false),
      is_form_loading: this.attr(true),
      is_form_error: this.attr(false),
      is_changing_page: this.attr(false),
      is_auth_modal: this.attr(false),
      is_save_for_later: this.attr(false),
      is_save_for_later_modal: this.attr(false),
      is_checkout: this.attr(false),
      is_preview: this.attr(false),
      completed_page_set: this.attr([]),
      sale_price: this.attr(0),
      related_documents: this.attr([]),
      purchased: this.attr(false),
      is_v2: this.attr(false),
      experiment_id: this.attr(0),
      variant_id: this.attr(0),
      update_company_field:this.attr(""),
      form_rendering_timestamp: this.attr(undefined),
    };
  }

  async createWithApi() {
    let { data } = await this.constructor.axios.post(
      `${this.constructor.entity}`,
      {
        document: this.data,
      }
    );
    let newOrder = await this.constructor.create({ data: data.document });
    return this.constructor.query().first();
  }

  static async loadActive(params) {
    let data = await this.findBy(params);
    await this.create({ data: data.document });
    return this.query().first();
  }

  get humanizedType() {
    return "Document";
  }

  get formName() {
    return liquidEngine.parseAndRenderSync(
      this.documentForm.name || "",
      this.data
    );  }

  get authTitle() {
    return `${this.formName} is ready for download`;
  }

  saveForLater(registered) {
    if(registered)
      this.sendSaveForLater()
    else
     this.$update({ is_auth_modal: true, is_save_for_later: true });
  }

  async sendSaveForLater() {
    this.$update({ is_save_for_later: true });
    fsEvent("Document > Save for Later", { version_slug: this.version_slug });
    let response = await this.constructor.axios.patch(
      `${this.constructor.findByUrl({ id: this.id })}/save_for_later`,
      {}
    );
    this.$update({ is_save_for_later_modal: true });
    this.$update({ is_save_for_later: false });
  }

  get documentForm() {
    return this.orderForm;
  }

  get orderFormParams() {
    return {
      orderId: this.id,
      version_slug: this.version_slug,
      order_type: "document",
    };
  }

  get route() {
    return "/documents/new";
  }

  async sendToDashboard(){
    window.location = `/account/documents/${this.id}`;
    return response
  }

  apiSave(data) {
    let companyData
    if(this.update_company_field) {
      if (!companyData) companyData = {}
      companyData[this.update_company_field] = this.data[this.update_company_field]
    }    

    let updatedData = {}
    if(companyData && (companyData !== {}))
    updatedData = {
      company: companyData ? companyData : undefined,
      document: {
        data: data ? data : this.data,
        current_page: this.current_page_id,
        ...(this.data.company_id ? {company_id: this.data.company_id} : {})
      }
    }

      else updatedData = {
        document: {
          data: data ? data : this.data,
          current_page: this.current_page_id,
          completed: this.completed,
          ...(this.data.company_id ? {company_id: this.data.company_id} : {})
        }
      }

    return this.apiUpdate({
      ...updatedData
    }).catch((e) => console.error(e));
  }

  loadPreview() {
    return this.constructor.axios
      .get(`${this.constructor.findByUrl({ id: this.id })}/preview`, {})
      .then(({ data }) => {
        return data.document;
      });
  }

  createSale(params) {
    return this.constructor.axios.post("/document_sale", params);
  }

  complete() {
    return this.constructor.axios.patch(
      `${this.constructor.findByUrl({ id: this.id })}/complete`,
      {}
    );
  }

  get eventType() {
    return "Document";
  }
}
