import BaseOrder from "./BaseOrder";
import Requests from "./Requests";

export default class Plan extends BaseOrder {
  static entity = "plans";

  static fields() {
    return {
      annual_plan: this.attr({}),
      monthly_plan: this.attr({}),
      single_purchase: this.attr({}),
      registered_agent: this.attr({}),
      compliance_guard: this.attr({}),
      other_plus_plan: this.attr({}),
    };
  }

  static async fetchAll() {
    const { data } = await this.axios.get(`/pricing`);
    Requests.markAsLoaded("plans");
    this.create({ data });
  }

  get freeTrial() {
    return this.trial_count && this.trial_count == 7;
  }
}
