import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {"environment":"production","appVersion":"1.0.0","apiKey":"ebf9d87229757e67e09ca62569fbd7d4","reporterOptions":{"appVersion":"e08d7ff71ce248914d1afd9ae8bd1f0fb46d7f34","autoAssignRelease":true},"enabledReleaseStages":["staging","production"],"publishRelease":true}
options.plugins = [new BugsnagPluginVue()]

let bugsnagClient

export default function ({ app }, inject) {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(options)
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}
