import BaseModel from "./BaseModel";
import Requests from "./Requests";
export default class UserCards extends BaseModel {
  static entity = "UserCards";

  static fields() {
    return {
      id: this.attr(null),
      first_name: this.attr(""),
      last_name: this.attr(""),
      postal_code: this.attr(""),
      card_type: this.attr(""),
      last_four: this.attr(""),
      exp_month: this.attr(null),
      exp_year: this.attr(null),
      primary_payment_method: this.attr(null),
      object: this.attr(""),
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/payment_methods`);
    await Requests.markAsLoaded('UserCards')
    await this.create({ data: data });
  }

  static async deleteCard(id) {
    await this.axios.delete(`/payment_methods/${id}`);
    await this.fetchAll();
  }

  static async makeDefault(id) {
    await this.axios.patch(`/payment_methods/${id}`, {
      primary_payment_method: true
    });
    await this.fetchAll();
  }
}
