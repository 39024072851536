import BaseModel from "./BaseModel";
import Requests from "./Requests";
export default class UserSubscriptions extends BaseModel {
  static entity = "UserSubscriptions";
  static validStates = [
    "incomplete",
    "trialing",
    "active",
    "past_due",
    "unpaid",
    "paused",
  ];
  static invalidStates = ["legacy_canceled", "incomplete_expired", "canceled"];

  static fields() {
    return {
      id: this.attr(null),
      plan_id: this.attr(null),
      payment_method_id: this.attr(null),
      state: this.attr(""),
      activated_at: this.attr(""),
      canceled_at: this.attr(""),
      trial_started_at: this.attr(""),
      trial_ends_at: this.attr(""),
      current_period_ends_at: this.attr(""),
      sub_type: this.attr(""),
      company_id: this.attr(null),
    };
  }

  static async fetchAll() {
    const { data } = await this.axios.get(`/subscriptions`);
    Requests.markAsLoaded("UserSubscriptions");
    data.forEach((subscription) => {
      this.insert({ data: subscription });
    });
  }

  static async startSubscription(newPlan, companyId, paymentMethodId) {
    await this.axios.post(`/subscriptions`, {
      plan_id: newPlan,
      company_id: companyId ? companyId : null,
      payment_method_id: paymentMethodId,
    });
    Requests.markAsNotLoaded("UserSubscriptions");
    this.fetchAll();
  }

  static async updatePlan(subscriptionId, newPlan) {
    await this.axios.patch(`/subscriptions/${subscriptionId}`, {
      plan_id: newPlan,
    });
    Requests.markAsNotLoaded("UserSubscriptions");
    this.fetchAll();
  }

  static async updateCard(id, newCard) {
    await this.axios.patch(`/subscriptions/${id}`, {
      payment_method_id: newCard,
    });
    Requests.markAsNotLoaded("UserSubscriptions");
    this.fetchAll();
  }

  static async cancel(id) {
    await this.axios.delete(`/subscriptions/${id}`);
    this.fetchAll();
  }

  isActive() {
    return UserSubscriptions.validStates.includes(this.state);
  }
  isInactive() {
    return UserSubscriptions.invalidStates.includes(this.state);
  }
}
